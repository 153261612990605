import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { format, subDays } from 'date-fns';

import ApiService from "../../services/ApiService";
import MailingPksModal from "../../components/Modals/Report/MailingPKSModal";
import Notifications from "../../components/Modals/Notifications";

const api = new ApiService();
const Report = () => {
    const [reportDates, setReportDates] = useState([]);
    const [showMailingPKS, setShowMailingPKS] = useState(false);
    const [queueNotifications, setQueueNotifications] = useState([]); // Уведомление

    useEffect(() => {
        const initialDates = Array.from({ length: 10 }, (_, index) => format(subDays(new Date(), index), 'dd.MM.yy'));
        setReportDates(initialDates);
    }, []);

    const handleClose = () => {
        setShowMailingPKS(false)
    };

    const downloadReportAboutUsers = async () => {
        const excel = await api.getReportAboutUsers()
            .then(async data => {
               if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                  let url = URL.createObjectURL(data);

                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'Отчет о сотрудниках.xlsx';
                  document.body.appendChild(a);
                  a.click();
                  a.remove();

                  URL.revokeObjectURL(url);
               } else {
                  if (data?.error) {
                     setQueueNotifications(queueNotifications.concat([data?.error]));
                  } else {
                     setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                  }
               }
            });
    };

    const downloadTemporaryQuestions = async (type) => {
        if (type === 'course') {
            await api.getExcelTemporaryCourseQuiestionFile()
                .then(async data => {
                   if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                      let url = URL.createObjectURL(data);

                      const a = document.createElement('a');
                      a.href = url;
                      a.download = 'Выгрузка временных вопросов.xlsx';
                      document.body.appendChild(a);
                      a.click();
                      a.remove();

                      URL.revokeObjectURL(url);
                   } else {
                      if (data?.error) {
                         setQueueNotifications(queueNotifications.concat([data?.error]));
                      } else {
                         setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                      }
                   }
                });
        } else {
            await api.getExcelTemporaryCustomQuiestionFile()
                .then(async data => {
                   if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                      let url = URL.createObjectURL(data);

                      const a = document.createElement('a');
                      a.href = url;
                      a.download = 'Выгрузка временных вопросов.xlsx';
                      document.body.appendChild(a);
                      a.click();
                      a.remove();

                      URL.revokeObjectURL(url);
                   } else {
                      if (data?.error) {
                         setQueueNotifications(queueNotifications.concat([data?.error]));
                      } else {
                         setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                      }
                   }
                });
        }
    };

    const downloadShopFile = async () => {
        const excel = await api.getShopExcelFile()
            .then(async data => {
               if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                  let url = URL.createObjectURL(data);

                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'Выгрузка по магазину.xlsx';
                  document.body.appendChild(a);
                  a.click();
                  a.remove();

                  URL.revokeObjectURL(url);
               } else {
                  if (data?.error) {
                     setQueueNotifications(queueNotifications.concat([data?.error]));
                  } else {
                     setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                  }
               }
            });
    };

    const downloadReportSalesPlan = async (date) => {
        const body = {
            reportDate: date
        }
        const excel = await api.getReportSalesPlan(body)
            .then(async data => {
                if (data.type !== "application/json") {
                    let url = URL.createObjectURL(data);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Отчет по планам продаж - ${date}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                    URL.revokeObjectURL(url);
                } else {
                    setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                }
            })
            .catch(e => {
                console.log(e)
            });
    };

    const downloadReportLastStatisticCourse = async () => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        const reportDate = format(currentDate, 'MM-yyyy');
        const excel = await api.getReportLastStatisticCourse()
            .then(async data => {
                if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    let url = URL.createObjectURL(data);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Отчет курсам за прошлый месяц и сезон за ${reportDate}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                    URL.revokeObjectURL(url);
                } else {
                    if (data?.error) {
                        setQueueNotifications(queueNotifications.concat([data?.error]));
                    } else {
                        setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                    }
                }
            })
            .catch(e => {
                console.log(e)
            });
    };

    const downloadReportLastStatisticCourseSeason = async () => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        const reportDate = format(currentDate, 'MM-yyyy');
        const excel = await api.getReportLastStatisticCourseSeason()
            .then(async data => {
                if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    let url = URL.createObjectURL(data);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Отчет курсам за прошлый месяц и сезон за ${reportDate}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                    URL.revokeObjectURL(url);
                } else {
                    if (data?.error) {
                        setQueueNotifications(queueNotifications.concat([data?.error]));
                    } else {
                        setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                    }
                }
            })
            .catch(e => {
                console.log(e)
            });
    };

   const downloadExcelActiveUsersToTerritoryStorePosition = async () => {
      const excel = await api.getExcelActiveUsersToTerritoryStorePosition()
        .then(async data => {
           if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
              let url = URL.createObjectURL(data);

              const a = document.createElement('a');
              a.href = url;
              a.download = 'Выгрузка по активным пользователям.xlsx';
              document.body.appendChild(a);
              a.click();
              a.remove();

              URL.revokeObjectURL(url);
           } else {
              if (data?.error) {
                 setQueueNotifications(queueNotifications.concat([data?.error]));
              } else {
                 setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
              }
           }
        });
   };

    return (
        <>
            <Notifications queueNotifications={queueNotifications} />
            <MailingPksModal
                reportDates={reportDates}
                downloadReport={downloadReportSalesPlan}
                show={showMailingPKS}
                close={handleClose}
            />

            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol sm='3'>
                            <MDBCard className={'h-100'}>
                                <MDBCardBody>
                                    <MDBCardTitle>Отчет по пользователям</MDBCardTitle>
                                    <MDBCardText>
                                        Отчет формируется за текущий сезон в xlsx формате
                                    </MDBCardText>
                                    <MDBBtn onClick={downloadReportAboutUsers}>Скачать</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol sm='3'>
                            <MDBCard className={'h-100'}>
                                <MDBCardBody>
                                    <MDBCardTitle>Отчет по временным вопросам</MDBCardTitle>
                                    <MDBCardText>
                                        Отчет по временным вопросам в xlsx формате
                                    </MDBCardText>
                                    <MDBRow className='d-flex align-items-center justify-content-center'>
                                        <MDBBtn onClick={() => downloadTemporaryQuestions('course')} style={{width: 250}}>Скачать (курсы)</MDBBtn>
                                        <MDBBtn onClick={() => downloadTemporaryQuestions('custom')} style={{width: 250}}>Скачать (функционал)</MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol sm='3'>
                            <MDBCard className={'h-100'}>
                                <MDBCardBody>
                                    <MDBCardTitle>Отчет по магазину</MDBCardTitle>
                                    <MDBCardText>
                                        Отчет по магазину в xlsx формате
                                    </MDBCardText>
                                    <MDBBtn onClick={downloadShopFile}>Скачать</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol sm='3'>
                            <MDBCard className={'h-100'}>
                                <MDBCardBody>
                                    <MDBCardTitle>Отчёт выполнения плана продаж</MDBCardTitle>
                                    <MDBCardText>
                                        Отчёт выполнения плана продаж за 10 дней
                                    </MDBCardText>
                                    <MDBBtn onClick={() => setShowMailingPKS(true)}>Открыть</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='mt-2'>
                        <MDBCol sm='3'>
                            <MDBCard className={'h-100'}>
                                <MDBCardBody>
                                    <MDBCardTitle>Отчёт по курсам</MDBCardTitle>
                                    <MDBCardText>
                                        Отчёт по курсам за прошлый месяц и сезон
                                    </MDBCardText>
                                    <MDBBtn onClick={downloadReportLastStatisticCourse}>Месяц</MDBBtn>
                                    <MDBBtn onClick={downloadReportLastStatisticCourseSeason}>Сезон</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                       <MDBCol sm='3'>
                            <MDBCard className={'h-100'}>
                                <MDBCardBody>
                                    <MDBCardTitle>Отчёт по активным пользователя</MDBCardTitle>
                                    <MDBCardText>
                                        Отчёт по активным пользователя в разрезе точек и территорий
                                    </MDBCardText>
                                    <MDBBtn onClick={downloadExcelActiveUsersToTerritoryStorePosition}>Скачать</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </main>
        </>
    );
};

export default Report;