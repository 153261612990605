export const BACK_URL = process.env.REACT_APP_BACK;
export default class ApiService {
    async graphQuery(query) {
        try {
            const res = await fetch(`${BACK_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({query}),
            })
            if (!res.ok) {
                window.alert("Произошла ошибка");
            } else {
                return await res.json().catch((e) => e);
            }
        } catch (e) {
            console.log(e)
        }
    }

    async graphMutation(query, variables) {
        try {
            const res = await fetch(`${BACK_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(
                    {
                        query,
                        variables
                    }),
            });
            if (!res.ok) {
                window.alert("Произошла ошибка");
            } else {
                return await res.json().catch((e) => e);
            }
        } catch (e) {
            console.error(e)
            throw e
        }

    }

    async getVideoCategory() {
        return await this.graphQuery(`
        {
            VideoTrainingCategories {
                video_training_category_id
                video_training_category_name
            }
        }
        `);
    }

    async getFullVideoCategory() {
        return await this.graphQuery(`
        {
            FullVideoTrainingCategories{
                video_training_category_id
                video_training_category_name
            }
        }
        `);
    }

    async getVideos() {
        return await this.graphQuery(`
        {
            VideoTrainingCategories {
                video_training_category_id
                video_training_category_name
                video_trainings {
                    video_training_id
                    video_training_name
                }
            }
        }
        `);
    }

    async getVideo(video_training_id) {
        return await this.graphQuery(`
            {
                VideoTrainings(video_training_id: ${video_training_id}) {
                    video_training_name
                    success
                    failure
                    preface
                    video
                    questions {
                      video_training_question_id
                      question
                      answers
                      right_answers
                    }
                    cookies
                    timer
                }
            }
        `);
    }

    async updateVideo(video_type) {
        return await this.graphMutation(`
            mutation editVideo(
                $video_training_id: ID!,
                $video_training_name: String,
                $success: String,
                $failure: String,
                $video: String,
                $cookies: Int,
                $timer: String,
            ) {
                editVideo(
                    video_training_id: $video_training_id
                    video_training_name: $video_training_name
                    success: $success
                    failure: $failure
                    video: $video
                    timer: $timer
                    cookies: $cookies   
                )
            }
        `, video_type)
    }

    async updateVideoQuestions(video_training_id, questions) {
        return await this.graphMutation(`
            mutation editVideoQuestions(
                $video_training_id: ID,
                $questions: [QuestionInputType],
            ) {
                editVideoQuestions(
                    video_training_id: $video_training_id
                    questions: $questions
                )
            }
        `, {
            video_training_id,
            questions
        })
    }

    async addVideo(video_type) {
        return await this.graphMutation(`
            mutation addVideo(
                $video_training_name: String!,
                $video: String!,
                $preface: String!,
                $success: String!,
                $failure: String!,
                $video_training_category_id: ID!,
                $timer: String!,
            ) {
              addVideo (
                video_training_name: $video_training_name
                video: $video
                preface: $preface
                success: $success
                failure: $failure
                video_training_category_id: $video_training_category_id
                timer: $timer
              ) {
                video_training_id
              }
            }
        `, video_type)
    }

    async addVideoQuestions(video_training_id, questions) {
        return await this.graphMutation(`
            mutation addVideoQuestions(
                $video_training_id: ID!,
                $questions: [QuestionInputType]!
            ) {
              addVideoQuestions (
                video_training_id: $video_training_id
                questions: $questions
              )
            }
        `, {
            video_training_id,
            questions
        })
    }

    async deleteVideoTraining(video_training_id) {
        return await this.graphMutation(`
            mutation deleteVideoTraining(
                $video_training_id: ID!
            ) {
              deleteVideoTraining (
                video_training_id: $video_training_id
              )
            }
        `, {
            video_training_id
        })
    }

    async getAdmin() {
        return await this.graphQuery(`
        query {
           Admin {
                admin_id
                admin_name
                permission
          }
        }
        `);
    }

    async getRoutes() {
        return await this.graphQuery(`{
          ConsoleRoutes {
            route {
              route_name
              route_icon
              route_path
            }
            children {
              route_name
              route_icon
              route_path
            }
          }
        }`)
    }

    async getUsers() {
        return await this.graphQuery(`{
          Users {
            user_id
            is_intern
            user_name
            full_name
            score
            token
            guid
            phone
            became_member_date
            become_intern_date
            become_staff_date
            last_activity
            position {
              position_name
              position_id
            }
            store {
              store_id
              number
              territory {
                territory_id
                number
              }
            }
            completed_dish_categories {
              completed {
                dish_category_id
              }
              no_completed {
                dish_category_id
              }
            }
            completed_custom_categories {
              completed {
                custom_category_id
              }
              no_completed {
                custom_category_id
              }
            }
            completed_video_trainings {
              completed {
                video_training_id
              }
              no_completed{
                video_training_id
              }
            }
            curses_info {
              completed_internships {
                structured_course_name
                structured_course_id
              }
              active_internship {
                structured_course_name
                structured_course_id
              }
            }
          }
        }`)
    }

    async getUsersShortListByFilter(filter = {
        position_ids: "[]",
        store_ids: "[]",
        territory_ids: "[]",
        is_active: 1,
        is_intern: 0
    }) {
        return await this.graphQuery(`{
          Users(
          position_ids: ${filter.position_ids} 
          store_ids: ${filter.store_ids}  
          territory_ids: ${filter.territory_ids}
          is_active: ${filter.is_active}
          is_intern: ${filter.is_intern}
          ) {
            user_id
            full_name
          }
        }`)
    }

    async getInterns() {
        return await this.graphQuery(`{
          Users(interns: true) {
            user_id
            is_intern
            user_name
            full_name
            score
            guid
            became_member_date
            become_intern_date
            become_staff_date
            last_activity
            phone
            position {
              position_name
              position_id
            }
            store {
              store_id
              number
              territory {
                territory_id
                number
              }
            }
            internship_info {
              completed_internships {
                structured_course_name
              }
              missed_internships{
                structured_course_name
              }
              active_internship {
                structured_course_name
                structured_course_id
                active_day {
                  structured_day_name
                  active_question {
                    element_index
                  }
                }
              }
            }
            curses_info {
              completed_internships {
                structured_course_name
                structured_course_id
              }
              active_internship {
                structured_course_name
                structured_course_id
              }
            }
          }
        }`)
    }

    async getLinkers() {
        return await this.graphQuery(`{
          Users(linkers: true) {
            user_id
            token
            guid
            full_name
            became_member_date
            become_intern_date
            become_staff_date
            phone
            position {
              position_name
              position_id
            }
            store {
              store_id
              number
              territory {
                territory_id
                number
              }
            }
            curses_info {
              completed_internships {
                structured_course_name
                structured_course_id
              }
              active_internship {
                structured_course_name
                structured_course_id
              }
            }
          }
        }`)
    }

    async getUserById(userId) {
        return await this.graphQuery(`
        {
          Users(user_id: ${userId}) {
            user_name
            user_id
            telegram_id
            full_name
            score
            phone
            token
            guid
            last_activity
            is_invite_to_steady_control
            is_intern
            became_member_date
            become_staff_date
            position {
              position_name
              position_id
            }
            store{
              territory {
                number
              }
              number
              store_id
            }
            completed_dish_categories {
              completed {
                dish_category_name
                date
              }
              no_completed {
                dish_category_name 
                completed_pick_ingredient {
                  dish_id   
                }
                completed_name_dish {
                  dish_id
                }
                no_completed_pick_ingredient {
                  dish_id
                }
                no_completed_name_dish {
                  dish_id
                }
              }
            }
            completed_custom_categories {
              completed{
                custom_category_name
                date
              }
              completed_prev_season {
                    custom_category_name
                    date
              }
              no_completed {
                custom_category_name
                completed_questions{
                  custom_question_id
                }
                no_completed_questions {
                   custom_question_id
                }
              }
            }
            completed_video_trainings{
              completed {
                video_training_name
                date
              }
              no_completed {
                video_training_name
              }
              completed_prev_season {
                video_training_name
                date
              }
            }
            certifications {
              certification_session_id
              certification_name
              date
              video_link
              number_of_questions
              number_of_correct_answers
            }
            curses_info {
              completed_internships {
                structured_course_name
                finished_date
              }
              active_internship {
                structured_course_name
              }
              completed_internships_prev_season {
                structured_course_name
                finished_date
              }
            }
          }
        }
        `);
    }

    async addUser(userInput) {
        return await this.graphMutation(`
            mutation addUser(
                $input: UserInputType!
            ) {
                addUser (input: $input) {
                    user_id
                    is_intern
                    user_name
                    full_name
                    score
                    token
                    guid
                    phone
                    became_member_date
                    position {
                      position_name
                      position_id
                    }
                    store {
                      store_id
                      number
                      territory {
                        number
                      }
                    }
                    completed_dish_categories {
                      completed {
                        dish_category_id
                      }
                      no_completed {
                        dish_category_id
                      }
                    }
                    completed_custom_categories {
                      completed {
                        custom_category_id
                      }
                      no_completed {
                        custom_category_id
                      }
                    }
                    completed_video_trainings {
                      completed {
                        video_training_id
                      }
                      no_completed{
                        video_training_id
                      }
                    }
                    curses_info {
                      completed_internships {
                        structured_course_name
                      }
                      active_internship {
                        structured_course_name
                      }
                    }
                  }
            }
        `, userInput)
    }

    async editUser(userInput) {
        return await this.graphMutation(`
            mutation editUser(
              $user_id: ID!,
              $input: UserInputType
            ) {
              editUser (
                user_id: $user_id
                input: $input
              ) {
                    user_id
                    is_intern
                    user_name
                    full_name
                    score
                    token
                    guid
                    phone
                    became_member_date
                    position {
                      position_name
                      position_id
                    }
                    store {
                      store_id
                      number
                      territory {
                        number
                      }
                    }
                    completed_dish_categories {
                      completed {
                        dish_category_id
                      }
                      no_completed {
                        dish_category_id
                      }
                    }
                    completed_custom_categories {
                      completed {
                        custom_category_id
                      }
                      no_completed {
                        custom_category_id
                      }
                    }
                    completed_video_trainings {
                      completed {
                        video_training_id
                      }
                      no_completed{
                        video_training_id
                      }
                    }
                    curses_info {
                      completed_internships {
                        structured_course_name
                        structured_course_id
                      }
                      active_internship {
                        structured_course_name
                        structured_course_id
                      }
                    }
                  }
            }
        `, userInput)
    }

    async removeUser(userInput) {
        return await this.graphMutation(`
            mutation editUser(
              $user_id: ID!,
            ) {
              removeUser (
                user_id: $user_id
              )
            }
        `, userInput)
    }

    async getPositions() {
        return await this.graphQuery(`{
            Positions {
                position_id
                position_name
                is_master
                 send_reating
                 dish_categories {
                   dish_category_id
                   dish_category_name
                 }
                 custom_categories {
                   custom_category_id
                   custom_category_name
                 }
                 video_trainings {
                   video_training_id
                   video_training_name
                 }
                internship_courses {
                  structured_course_id
                  structured_course_name
                  default_is_mandatory
                  default_is_internship
                  default_element_index
                }
                 certifications {
                   certification_id
                   certification_name
                 }
                 countTodayActivity
           }
        }`)
    }

    async getSmallPositions() {
        return await this.graphQuery(`{
          Positions {
            position_id
            position_name
            is_master
          }
        }`)
    }

    async getSmallPositionsByPositionId(positionId) {
        return await this.graphQuery(`{
          Positions(position_id: ${positionId}) {
            position_id
            position_name
          }
        }`)
    }

    async addPosition(positionInput) {
        return await this.graphMutation(`
            mutation addPosition(
                $input: PositonsInputType!
            ) {
              addPosition (input: $input) {
                position_id
                position_name
                is_master
                send_reating
                dish_categories {
                  dish_category_id
                  dish_category_name
                }
                custom_categories {
                  custom_category_id
                  custom_category_name
                }
                video_trainings {
                  video_training_id
                  video_training_name
                }
                internship_courses {
                  structured_course_id
                  structured_course_name
                  default_is_mandatory
                  default_is_internship
                  default_element_index
                }
                certifications {
                  certification_id
                  certification_name
                }
              }
            }
        `, positionInput)
    }

    async editPosition(positionInput) {
        return await this.graphMutation(`
            mutation editPosition(
                $position_id: ID!,
                $input: PositonsInputType!
            ) {
              editPosition (
                  position_id: $position_id
                  input: $input
              ) {
                    position_id
                    position_name
                    is_master
                    send_reating
                    dish_categories {
                      dish_category_id
                      dish_category_name
                    }
                    custom_categories {
                      custom_category_id
                      custom_category_name
                    }
                    video_trainings {
                      video_training_id
                      video_training_name
                    }
                    internship_courses {
                      structured_course_id
                      structured_course_name
                      default_is_mandatory
                      default_is_internship
                      default_element_index
                    }
                    certifications {
                      certification_id
                      certification_name
                    }
                }
            }
        `, positionInput)
    }

    async removePosition(positionInput) {
        return await this.graphMutation(`
            mutation removePosition(
              $position_id: ID!
            ) {
              removePosition (
                position_id: $position_id
              )
            }
        `, positionInput)
    }

    async getDishesCategories() {
        return await this.graphQuery(`{
          DishesCategories {
            dish_category_id
            dish_category_name
            icon_link
            is_banned
            dishes {
              dish_id
              dish_name
            }
          }
        }`)
    }

    async getDishesByCategoryId(id) {
        return await this.graphQuery(`{
              Dishes(dish_category_id: ${id}) {
                dish_id
                dish_name
                image
                is_new
                ingredients {
                  ingredient_id
                  ingredient_name
                }
            }
        }`)
    }

    async removeDishCategory(dishCategoryInput) {
        return await this.graphMutation(`
            mutation DishCategory(
              $dish_category_id: ID!
            ) {
              removeDishCategory (
                dish_category_id: $dish_category_id
              )
            }
        `, dishCategoryInput)
    }

    async addDishCategory(dishCategoryInput) {
        return await this.graphMutation(`
            mutation DishCategory(
                $dish_category_name: String!,
                $icon_link: String,
                $is_banned: ID!,
                $dishes: [ID]!,
            ) {
              addDishCategory( input: {
                dish_category_name: $dish_category_name
                icon_link: $icon_link
                is_banned: $is_banned
                dishes: $dishes
              }) {
                dish_category_id
                dish_category_name
                icon_link
                is_banned
                dishes {
                  dish_id
                  dish_name
                }
              }
            }
        `, dishCategoryInput)
    }

    async editDishCategory(dishCategoryEdit) {
        return await this.graphMutation(`
            mutation DishCategory(
                $dish_category_id: ID!,
                $dish_category_name: String!,
                $icon_link: String,
                $is_banned: ID!
            ) {
              editDishCategory( input: {
                dish_category_id: $dish_category_id
                dish_category_name: $dish_category_name
                icon_link: $icon_link
                is_banned: $is_banned
              }) {
                dish_category_id
                dish_category_name
                icon_link
                is_banned
                dishes {
                  dish_id
                  dish_name
                }
              }
            }
        `, dishCategoryEdit)
    }

    async changeIngredient(inputData) {
        return await this.graphMutation(`
            mutation 
            changeIngredient(
                $dish_category_id: ID!,
                $old_ingredient_id: ID!,
                $new_ingredient_id: ID!,
            ) {
              changeIngredients(
                dish_category_id: $dish_category_id
                old_ingredient_id: $old_ingredient_id
                new_ingredient_id: $new_ingredient_id
              )
            }
        `, inputData)
    }

    async changeDishStatus(inputData) {
        return await this.graphMutation(`
            mutation changeDishNewStatus(
                $dish_id: ID!,
                $mode: Boolean!
            ) {
              changeDishNewStatus(
                dish_id: $dish_id
                new_mode: $mode
              ) {
                dish_id
              }
            }
        `, inputData)
    }

    async editDishIngredients(inputData) {
        return await this.graphMutation(`
            mutation addOrDeleteIngredientToDish(
                $dish_id: ID!,
                $ingredients: [String],
                $newIngredient: String
            ) {
              addOrDeleteIngredientToDish(
                dish_id: $dish_id
                ingredients: $ingredients
                newIngredient: $newIngredient
              )
            }
        `, inputData)
    }

    async getStores() {
        return await this.graphQuery(`{
          Stores {
            store_id
            number
          }
        }`)
    }

    async getStoresByTerritoryId(territory_ids) {
        return await this.graphQuery(`{
          Stores(territory_ids: ${JSON.stringify(territory_ids)}) {
            store_id
            number
          }
        }`)
    }

    async getTerritoryes() {
        return await this.graphQuery(`{
          Territoryes{
            territory_id
            number
          }
        }`)
    }

    async getPositionsForStructuredCourse(courseId) {
        const res = await fetch(`${BACK_URL}/positions/${courseId}`, {
            method: 'GET',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getUsersForStructuredCourse(courseId) {
        const res = await fetch(`${BACK_URL}/usersCourses/${courseId}`, {
            method: 'GET',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getLengthNewUsers() {
        const res = await fetch(`${BACK_URL}/getCountNewUsers`, {
            method: 'GET',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getExcelTemporaryCourseQuiestionFile() {
        const res = await fetch(`${BACK_URL}/getTemporaryQuestions`, {
            method: 'POST',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getExcelActiveUsersToTerritoryStorePosition() {
        const res = await fetch(`${BACK_URL}/getActiveUsersToTerritory`, {
            method: 'POST',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getExcelTemporaryCustomQuiestionFile() {
        const res = await fetch(`${BACK_URL}/getTemporaryCustomQuestions`, {
            method: 'POST',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getShopExcelFile() {
        const res = await fetch(`${BACK_URL}/getShopExcel`, {
            method: 'POST',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async editCourseWithModal(data) {
        const res = await fetch(`${BACK_URL}/editPositionCourse`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async editUsersCourseWithModal(data) {
        const res = await fetch(`${BACK_URL}/editUsersCourse`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getHistoryUserExcel(user_id) {
        const res = await fetch(`${BACK_URL}/getHistoryUserExcel`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user_id}),
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getReportAboutUsers() {
        const res = await fetch(`${BACK_URL}/getReportAboutUsers`, {
            method: 'POST',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getReportSalesPlan(body) {
        const res = await fetch(`${BACK_URL}/getMailingPksAndMM`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.blob().catch((e) => e);
    }

    async getReportLastStatisticCourse(body) {
        const res = await fetch(`${BACK_URL}/getLastStatisticCourses`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getReportLastStatisticCourseSeason(body) {
        const res = await fetch(`${BACK_URL}/getLastStatisticCoursesSeason`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        const contentType = res.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return await res.json().catch((e) => e);
        } else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return await res.blob().catch((e) => e);
        } else {
            return await res.json().catch((e) => e);
        }
    }

    async getPosts() {
        const res = await fetch(`${BACK_URL}/telegram/posts`, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }


    async getUsersIds(id) {
        const res = await fetch(`${BACK_URL}/telegram/usersIds/` + id, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async transferOnStaff(data) {
        const res = await fetch(`${BACK_URL}/transfer-onStaff`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getMetrics() {
        const res = await fetch(`${BACK_URL}/get-metrics`, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getFilePathsForPost(id) {
        const res = await fetch(`${BACK_URL}/telegram/post/` + id, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async sendPost(formData) {
        return fetch(`${BACK_URL}/to_telegram`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        });
    }

    async editPost(formData, id) {
        const res = await fetch(`${BACK_URL}/to_telegram/edit/` + id, {
            method: 'PATCH',
            credentials: 'include',
            body: formData
        });
        return await res.json().catch((e) => e);
    }

    async editRegionDish(updateDish) {
        const res = await fetch(`${BACK_URL}/setDishByRegion`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateDish)
        });
        return await res.json().catch((e) => e);
    }

    async getInfoStoreById(storeId) {
        const res = await fetch(`${BACK_URL}/getInfoStore/` + storeId, {
            credentials: 'include',
        });
        return await res.json().catch((e) => e);
    }

    async getTerritoriesByRegion() {
        const res = await fetch(`${BACK_URL}/territory/get-by-region`, {
            credentials: 'include',
        })
        const data = await res.json().catch((e) => e);

        if (res.ok) {
            return data
        } else {
            throw new Error(data.error);
        }
    }

    async getStoresByTerritory(id) {
        const res = await fetch(`${BACK_URL}/store/get-by-territory?territory_id=${id}`, {
            credentials: 'include',
        })
        const data = await res.json().catch((e) => e);

        if (res.ok) {
            return data
        } else {
            throw new Error(data.error);
        }
    }

    async addStoreForTerritory(data) {
        const res = await fetch(`${BACK_URL}/store/add` , {
            credentials: 'include',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async deleteStoreForTerritory(id) {
        const res = await fetch(`${BACK_URL}/store/delete-by-id?id=${id}` , {
            credentials: 'include',
            method: "DELETE"
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async deleteTerritory(id) {
        const res = await fetch(`${BACK_URL}/territory/delete-by-id?id=${id}` , {
            credentials: 'include',
            method: "DELETE"
        })

        const resData = await res.json().catch((e) => e);

        if (res.ok) {
            return resData
        } else {
            throw new Error(resData.error);
        }
    }

    async addTerritory(data) {
        const res = await fetch(`${BACK_URL}/territory/add` , {
            credentials: 'include',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const resData = await res.json().catch((e) => e);

        if (res.ok) {
            return resData
        } else {
            throw new Error(resData.error);
        }
    }

    async editTerritory(data) {
        const res = await fetch(`${BACK_URL}/territory/edit` , {
            credentials: 'include',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const resData = await res.json().catch((e) => e);

        if (res.ok) {
            return resData
        } else {
            throw new Error(resData.error);
        }
    }

    async editStore(data) {
        const res = await fetch(`${BACK_URL}/store/edit` , {
            credentials: 'include',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const resData = await res.json().catch((e) => e);

        if (res.ok) {
            return resData
        } else {
            throw new Error(resData.error);
        }
    }

    async uploadFileCourse(formData) {
        const res = await fetch(`${BACK_URL}/uploadCourse-file`, {
            credentials: 'include',
            method: 'POST',
            body: formData
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }
    async downloadFileCourse(body) {
        const res = await fetch(`${BACK_URL}/downloadCourse-file`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        if (!res.ok) {
            return await res.json()
        }
        return await res.blob().catch((e) => e);
    }

    async getNotification(date) {
        let new_date = (dates) => {
            let first_date = dates[0].getFullYear() + "-" + (+dates[0].getMonth() + 1) + "-" + dates[0].getDate();
            if (dates[1]) {
                let second_date = dates[1].getFullYear() + "-" + (+dates[1].getMonth() + 1) + "-" + dates[1].getDate();
                return [first_date, second_date]
            }
            return [first_date, first_date]
        }

        const res = await fetch(`${BACK_URL}/notification`, {
            credentials: 'include',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(new_date(date))
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getMessagesByUser(userId, currentPage) {
        const res = await fetch(`${BACK_URL}/getHistoryMessage/` + userId + `?page=${currentPage}&pageSize=100`, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getUserPhotoById(tgId){
        const res = await fetch(`${BACK_URL}/getUserPhotoByTgId/` + tgId, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getPostsById(id) {
        const res = await fetch(`${BACK_URL}/telegram/posts/` + id, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async deletePost(id) {
        const res = await fetch(`${BACK_URL}/to_telegram/delete/` + id, {
            method: 'DELETE',
            credentials: 'include',
        });
        return await res.json().catch((e) => e);
    }

    async deletePostInTg(id) {
        const res = await fetch(`${BACK_URL}/to_telegram/deleteMsg/` + id, {
            method: 'DELETE',
            credentials: 'include',
        });
        return await res.json().catch((e) => e);
    }

    async getRegionSettings() {
        const res = await fetch(`${BACK_URL}/getSettingRegion`, {
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async editRegionSettings(json) {
        const res = await fetch(`${BACK_URL}/editSettingRegion`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json)
        });
        return await res.json().catch((e) => e);
    }

    async getCertificationSessionById(id) {
        return await this.graphQuery(`{
          CertificationSessions(certification_session_id: ${id}) {
              certification_session_id
              certification_name
              number_of_questions
              number_of_correct_answers
              date
              questions {
                  certification_session_question_id
                  mode
                  question
                  is_correct
                  answers{
                    answer
                    replyCode
                  }
                  correct_answers
                  user_answers
                  image
              }
            }
        }`)
    }

    async getCertificationById(id) {
        return await this.graphQuery(`{
              Certifications(certification_id: ${id}) {
                  certification_subcategories {
                  certification_subcategory_id
                  certification_subcategory_name
                  sample_size
                  certification_questions {
                    certification_question_id
                  }
                }
              }
          }`)
    }

    async getCertificationList() {
        return await this.graphQuery(`{
              Certifications {
                certification_id
                certification_name
                certification_subcategories {
                  certification_subcategory_id
                }
              }
          }`)
    }

    async getCertificationSubcategoryQuestions(certification_subcategory_id) {
        return await this.graphQuery(`{
            CertificationSubcategoryQuestions(certification_subcategory_id: ${certification_subcategory_id}) {
                certification_question_id
                question
                right_answers
                answers
              }
        }`)
    }

    async getCertification1cLink() {
        return await this.graphQuery(`{
             Certification1cLink {
                user_id
                user_name
                full_name
                phone
                position_name
                store_name
                links
             }
        }
    `)
    }

    // async getCertificationToUser(Certification) {
    //     return await this.graphQuery(`{
    //       CertificationToUser(certification_id: ${Certification.certification_id}) {
    //         id
    //         is_completed
    //         date
    //         user {
    //           user_id
    //           full_name
    //           position {
    //             position_id
    //             position_name
    //           }
    //           store {
    //             number
    //             territory {
    //               number
    //             }
    //           }
    //         }
    //       }
    //       }`)
    // }

    async removeDish(dishInput) {
        return await this.graphMutation(`
            mutation removeDish(
              $dish_id: ID!
            ) {
              removeDish (
                dish_id: $dish_id
              )
            }
        `, dishInput)
    }

    // async writeCertificationsToUsers(input_data) {
    //     return await this.graphMutation(`
    //         mutation writeCertificationsToUsers(
    //           $certification_id: ID
    //           $user_ids: [ID]
    //         ) {
    //           writeCertificationsToUsers(
    //             certification_id: $certification_id
    //             user_ids: $user_ids
    //           )
    //         }
    //     `, input_data)
    // }

    async addWelcomeMassage(formData) {
        let res = await fetch(`${BACK_URL}/add_welcome`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return res
    }
    async addWelcomeMassageState(formData) {
        let res = await fetch(`${BACK_URL}/add_welcomeState`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return res
    }

    async getWelcomeMassages(position_id) {
        const res = await fetch(`${BACK_URL}/welcome/${position_id}`, {
            method: 'GET',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
            throw(e)
        });
        return await res.json().catch((e) => e);
    }

    async getStructuredCourses() {
        return await this.graphQuery(`{
        StructuredCourses {
                structured_course_id
                structured_course_name
                structured_course_text
                structured_days{
                  structured_day_id,
                  element_index
                }
                cookies
                region_id
                is_time_limit
                file
            }
        }`)
    }

    async getStructuredCoursesArchive() {
        return await this.graphQuery(`{
        StructuredCourses (is_archive: true) {
                structured_course_id
                structured_course_name
                structured_days{
                  structured_day_id,
                  element_index
                }
                cookies
                region_id
                is_time_limit
            }
        }`)
    }

    async getStructuredCourseById(id) {
        return await this.graphQuery(`{
        StructuredCourses (structured_course_id: ${id}) {
                structured_course_id
                structured_course_name
                structured_course_text
                structured_days{
                  structured_day_id,
                  element_index
                }
                cookies
                region_id
                is_time_limit
            }
        }`)
    }

    async getInternshipCoursesById(id) {
        return await this.graphQuery(`{
          InternshipCourses (internship_course_id: ${id}) {
            internship_course_id
            structured_course_name
            internship_days {
              internship_day_id
              name: internship_day_name
              element_index
              questions: internship_questions {
                internship_question_id
                element_index
                question
                answers
                right_answer: right_answers
                path: file_path
              }
            }
          }
        }`)
    }

    async getStructuredDays(internship_course_id) {
        return await this.graphQuery(`{
          StructuredDays(structured_course_id: ${internship_course_id}) {
            structured_day_id
            structured_day_name
            element_index
            structured_questions {
              structured_question_id
            }
          }
        }`)
    }

    async getStructuredQuestions(internship_day_id) {
        return await this.graphQuery(`{
            StructuredQuestions(structured_day_id: ${internship_day_id}) {
                structured_question_id
                element_index
                question
                answers
                right_answers
                file_id
                file_path
                is_editable
            }
        }`)
    }

    async addStructuredCourse(structured_course_name, structured_course_text, cookies, is_time_limit) {
        return await this.graphMutation(`
            mutation addStructuredCourse (
                  $structured_course_name: String!
                  $structured_course_text: String
                  $cookies: Int
                  $is_time_limit: Boolean
                ) {
                  addStructuredCourse(structured_course_name: $structured_course_name, structured_course_text: $structured_course_text, cookies: $cookies, is_time_limit: $is_time_limit)
            }
        `, {structured_course_name, structured_course_text, cookies, is_time_limit})
    }

    async addStructuredDay(structured_day_name, structured_course_id, element_index) {
        return await this.graphMutation(`
            mutation addStructuredDay (
                  $structured_day_name: String!
                  $structured_course_id: ID!
                  $element_index: Int!
                ) {
                  addStructuredDay(structured_day_name: $structured_day_name, structured_course_id: $structured_course_id, element_index: $element_index)
            }
        `, {structured_day_name, structured_course_id, element_index})
    }

    async addStructuredQuestion(input) {
        return await this.graphMutation(`
            mutation addStructuredQuestion(
                $structured_day_id: ID!
                $element_index: Int!
                $QuestionData: QuestionInputType!
                $image: String
                $is_editable: Int
            ) {
              addStructuredQuestion(
                structured_day_id: $structured_day_id
                element_index: $element_index
                QuestionData: $QuestionData
                image: $image
                is_editable: $is_editable
              )
            }
        `, input)
    }

    async addInternshipsForUser(user_id, internships) {
        return await this.graphMutation(`
            mutation addInternshipDay (
                  $user_id: ID!
                  $internships: [InternshipsForUserInputType]!
                ) {
                  addInternshipsForUser(user_id: $user_id, internships: $internships)
            }
        `, {user_id, internships})
    }

    async editStructuredCourseNameAndCookie(structured_course_id, new_structured_course_name, cookies, isBlocked) {
        return await this.graphMutation(`
            mutation {
                 editStructuredCourseNameAndCookie (structured_course_id: ${structured_course_id}, new_structured_course_name: "${new_structured_course_name}", cookies: ${+cookies}, is_time_limit: ${+isBlocked}) 
            }
        `,)
    }

    async editStructuredCourseText(structured_course_id, new_structured_course_text) {
        return await this.graphMutation(`
            mutation editStructuredCourseText ($structured_course_id: ID, $new_structured_course_text: String) {
                 editStructuredCourseText(structured_course_id: $structured_course_id, new_structured_course_text: $new_structured_course_text)
            }
        `, { structured_course_id, new_structured_course_text });
    }

    async editStructuredCourseIsArchive(structured_course_id, is_archive) {
        return await this.graphMutation(`
            mutation {
                 editStructuredCourseIsArchive (structured_course_id: ${structured_course_id}, is_archive: ${is_archive}) 
            }
        `,)
    }

    async editVideoTraining(video_training_id, cookies, timer) {
        return await this.graphMutation(`
            mutation {
                 editVideoTraining (video_training_id: ${+video_training_id}, cookies: ${+cookies}, timer: "${timer}") 
            }
        `,)
    }

    async removeStructuredCourse(structured_course_id) {
        return await this.graphMutation(`
            mutation {
                 removeStructuredCourse (structured_course_id: ${structured_course_id}) 
            }
        `,)
    }

    async removeStructuredDay(structured_day_id) {
        return await this.graphMutation(`
            mutation {
                 removeStructuredDay(structured_day_id: ${structured_day_id}) 
            }
        `,)
    }

    async removeStructuredQuestions(structured_question_id) {
        return await this.graphMutation(`
            mutation {
                 removeStructuredQuestions(structured_question_id: ${structured_question_id}) 
            }
        `,)
    }

    async editStructuredDayName(structured_day_id, new_structured_day_name) {
        return await this.graphMutation(`
            mutation {
                 editStructuredDayName(structured_day_id: ${structured_day_id}, new_structured_day_name: "${new_structured_day_name}") 
            }
        `,)
    }

    async editStructuredQuestion(input) {
        return await this.graphMutation(`
            mutation editStructuredQuestion(
                $structured_question_id: ID!
                $newDataQuestion: QuestionInputType
                $image: String
                $is_editable: Int
            ) {
              editStructuredQuestion(
                structured_question_id: $structured_question_id
                newDataQuestion: $newDataQuestion
                image: $image
                is_editable: $is_editable
              )
            }
        `, input)
    }

    async joinStructuredQuestion(input) {
        return await this.graphMutation(`
            mutation joinStructuredQuestion(
                $structured_day_id: ID!
                $QuestionData: QuestionInputType!
                $image: String
                $is_editable: Int
            ) {
              joinStructuredQuestion(
                structured_day_id: $structured_day_id
                QuestionData: $QuestionData
                image: $image
                is_editable: $is_editable
              )
            }
        `, input)
    }

    async editIndexStructuredQuestion(input) {
        return await this.graphMutation(`
            mutation editIndexStructuredQuestion(
                $structured_question_id: ID!,
                $element_index: Int!
            ) {
              editIndexStructuredQuestion(
                structured_question_id: $structured_question_id
                element_index:  $element_index
              ) 
            }
        `, input)
    }

    async getCustomCategoriesList() {
        return await this.graphQuery(`{
            CustomCategories {
                custom_category_id
                custom_category_name
                icon_link
            }
        }`)
    }

    async getCustomCategorieById(id) {
        return await this.graphQuery(`{
            CustomQuestions(custom_category_id: ${id}) {
                custom_question_id
                question
                answers
                right_answers
                image
                is_editable
            }
        }`)
    }

    async removeCustomQuestion(custom_question_id) {
        return await this.graphMutation(`
            mutation removeCustomQuestion(
              $custom_question_id: ID!
            ) {
              removeCustomQuestion (
                custom_question_id: $custom_question_id
              )
            }
        `, custom_question_id)
    }

    async removeCustomCategory(custom_category_id) {
        return await this.graphMutation(`
            mutation removeCustomCategory(
              $custom_category_id: ID!
            ) {
              removeCustomCategory (
                custom_category_id: $custom_category_id
              )
            }
        `, custom_category_id)
    }

    async addCustomCategory(custom_category_name) {
        return await this.graphMutation(`
            mutation addCustomCategory(
              $custom_category_name: String!
            ) {
              addCustomCategory (
                custom_category_name: $custom_category_name
              ) {
                custom_category_name
              }
            }
        `, custom_category_name);
    }

    async editCustomCategory(custom_category_id, custom_category_name) {
        return await this.graphMutation(`
            mutation {
                 editCustomCategory (custom_category_id: ${custom_category_id}, custom_category_name: "${custom_category_name}") 
            }
        `,)
    }

    async addCustomQuestion(custom_category_question) {
        return await this.graphMutation(`
            mutation addCustomQuestion(
              $custom_category_id: ID!,
              $newDataQuestion: QuestionInputType!,
              $image: String
              $is_editable: Int
            ) {
              addCustomQuestion (
                custom_category_id: $custom_category_id,
                newDataQuestion: $newDataQuestion,
                image: $image
                is_editable: $is_editable
              ) {
                answers
              }
            }
        `, custom_category_question);
    }

    async editCustomQuestion(input) {
        return await this.graphMutation(`
            mutation editCustomQuestion(
                $custom_question_id: ID!
                $newDataQuestion: QuestionInputType!
                $image: String
                $is_editable: Int
            ) {
              editCustomQuestion (
                custom_question_id: $custom_question_id
                newDataQuestion: $newDataQuestion
                image: $image
                is_editable: $is_editable
              ) {
                custom_question_id
                question
                answers
                right_answers
                image
                is_editable
              }
            }
        `, input)
    }

    async uploadFile(file) {
        let formData = new FormData();
        formData.append('file', file, file.name);

        return fetch(`${BACK_URL}/upload_file`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        });
    }

    async FAQContent() {
        return await this.graphQuery(`
            {
               FAQContent{
                    faq_category_id
                    faq_category_name
                    element_index
                    faq_content {
                        faq_content_id
                        faq_content_question
                        faq_content_answer
                        element_index
                    }
               }
            }
        `,)
    }

    async FAQParagraph(faq_content_id) {
        return await this.graphQuery(`
            {
              FAQParagraph(faq_content_id: ${faq_content_id}){
                faq_content_id
                faq_content_question
                faq_content_answer
                element_index
              }
            }
        `,)
    }

    async editFAQParagraph(input) {
        return await this.graphMutation(`
            mutation editFAQQuestion(
                $faq_content_id: ID!
                $title: String!
                $text: String!
            ) {
              editFAQQuestion (
                faq_content_id: $faq_content_id
                title: $title
                text: $text
              )
            }
        `, input)
    }

    async FAQCategoriesForAdd() {
        return await this.graphQuery(`
            {
               FAQContent {
                  faq_category_id
                  faq_category_name
                  element_index
                  faq_content {
                    faq_content_id
                    faq_content_question
                    element_index
                  } 
                }
            }
        `,)
    }

    async addFAQQuestion(input) {
        return await this.graphMutation(`
            mutation addFAQQuestion(
                $title: String
                $text: String
                $elementIndex: ID
                $groupName: String
                $groupId: ID
            ) {
              addFAQQuestion (
                title: $title
                text: $text
                groupName: $groupName
                groupId: $groupId
                elementIndex: $elementIndex
              )
            }
        `, input)
    }

    async getProducts() {
        return await this.graphQuery(`
            query getProducts{
              Products {
                product_id,
                product_name,
                description,
                price,
                quantity,
                element_index,
                file_id,
                file_path,
              }
            },
        `)
    }

    async addProduct(ProductInput) {
        return await this.graphMutation(`
            mutation addProduct($input: ShopProductInputType, $image: String){
              addProduct(input: $input, image: $image){
                product_id,
                product_name,
                description,
                price,
                quantity,
                file_id,
                file_path,
              }
            }
        `, ProductInput)
    }

    async editProduct(ProductInput) {
        return await this.graphMutation(`
            mutation editProduct($product_id: ID!, $input: ShopProductEditType, $image: String){
              editProduct(product_id: $product_id, input: $input, image: $image){
                product_id,
                product_name,
                description,
                price,
                quantity,
                file_id,
                file_path,
              }
            }
        `, ProductInput)
    }

    async editProductIndex(input) {
        return await this.graphMutation(`
            mutation editProductIndex(
                $product_id: ID!,
                $element_index: Int!
            ) {
              editProductIndex(
                product_id: $product_id
                element_index:  $element_index
              ) 
            }
        `, input)
    }

    async removeProduct(ProductInput) {
        return await this.graphMutation(`
            mutation deleteProduct($product_id: ID!){
              removeProduct(product_id: $product_id){
                product_name,
                description,
                price,
                quantity
              }
            }
        `, ProductInput)
    }

    async getBookings() {
        return await this.graphQuery(`
            query getBookings {
              Bookings {
                booking_id,
                is_completed,
                completed_at,
                user_id {
                  user_id
                  user_name
                  full_name
                  store {
                    number
                  }
                },
                product_id {
                  product_id
                  product_name
                  file_path
                },
                quantity,
                date,
                region_id {
                  region_name
                }
              }
            }
        `)
    }

    async editBooking(booking_id, product_id, user_id, is_completed, completed_at) {
        return await this.graphMutation(`
            mutation editBooking($booking_id: ID!, $product_id: ID!, $user_id: ID!, $is_completed: Int!, $completed_at: String!) {
              editBooking(booking_id: $booking_id, product_id: $product_id, user_id: $user_id, is_completed: $is_completed, completed_at: $completed_at) {
                is_completed,
                completed_at,
                product_id {
                  product_name
                },
                user_id {
                  user_name
                },
                quantity,
                date,
                region_id {
                  region_name
                }
              }
            }
        `, {booking_id, product_id, user_id, is_completed, completed_at})
    }

    async getSettingsShop() {
        return await this.graphQuery(`
            query getSettingsShop {
              ShopSettings {
                setting_id,
                region_id {
                  region_id,
                  region_name
                },
                success_message,
                deliveredOrder_message,
                file_success,
                file_deliveredOrder
              }
            }
        `)
    }

    async editSettingsShop(message, messageOrder, file_success, file_deliveredOrder) {
        return await this.graphMutation(`
            mutation editShopSetting($message: String, $messageOrder: String, $file_success: String, $file_deliveredOrder: String) {
              editShopSetting(success_message: $message, deliveredOrder_message: $messageOrder, file_success: $file_success, file_deliveredOrder: $file_deliveredOrder) {
                setting_id,
                region_id {
                  region_id
                  region_name
                },
                success_message,
                deliveredOrder_message,
                file_success,
                file_deliveredOrder
              }
            }
        `, {message, messageOrder, file_success, file_deliveredOrder})
    }
}
